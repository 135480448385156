#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.login-form-button {
  margin-left: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  padding: 20px;
}

button {
  padding: 10px;
  margin: 10px;
  background-color: orange;
  border: none;
  border-radius: 4px;
  transition: all 150ms;
}

button:active {
  background: red;
  border: none;
}

button:focus {
  border: none;
}

button:hover {
  transform: scale(1.2);
}

input {
  padding: 10px;
  border-radius: 4px;
}

.ant-form-item-label .ant-form-item-required {
  color: #2980b9;
}
