@font-face {
  font-family: Abel;
  src: url(Fonts/Abel-Regular.ttf);
}

@font-face {
  font-family: Kantumruy;
  src: url(Fonts/Kantumruy-Regular.ttf);
}

* {
  font-family: 'Kantumruy', 'Abel', -apple-system, BlinkMacSystemFont;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Kantumruy', 'Abel', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
input,
button,
a,
textarea {
  font-family: 'Kantumruy', 'Abel', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
